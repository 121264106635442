<template>
  <div class="navbar navbar-light bg-white border-bottom navbar-expand navbar-static-top" role="navigation">
    <div class="container-fluid px-0">
      <a :href="homePath" class="navbar-brand" title="ICIJ's single-sign-on platform">
        <brand-expansion :size="40" mode="long" class="mr-2 d-none d-md-inline-block" />
        <brand :size="40" class="mr-2 d-md-none" />
        <span class="sr-only"> Account </span>
      </a>

      <slot name="right-menu">
        <ul v-if="hasUser" class="navbar-nav ml-auto text-nowrap align-items-center">
          <li class="nav-item mr-3">
            <b-button href="https://support.cloud.icij.org/" variant="info">
              <fa icon="ambulance" fixed-width class="mr-2" />
              Ask for help
            </b-button>
          </li>
          <li v-if="hasApplications" class="nav-item mr-3">
            <b-dropdown
              variant="outline-dark"
              class="nav-item__applications__menu"
              menu-class="nav-item__applications__menu__content shadow-sm"
              lazy
              right
            >
              <template #button-content>
                <fa icon="grip" fixed-width class="mr-2" />
                Apps
              </template>
              <b-dropdown-item
                v-for="application in productionApplications"
                :key="application.name"
                :href="application.url"
                class="nav-item__applications__menu__content__item"
                link-class="rounded d-flex flex-column align-items-center p-2 h-100"
              >
                <img
                  v-if="application.logo_id"
                  :src="applicationLogoPath(application.logo_id)"
                  alt="logo"
                  height="60"
                />
                <img v-else src="@/assets/images/logo-lighter.svg" alt="logo" height="60" />
                <div class="mw-100 text-center small nav-item__applications__menu__content__item__name">
                  {{ application.name }}
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </li>
          <li v-if="isAdmin || isSupport" class="nav-item mr-3">
            <b-dropdown variant="outline-dark" menu-class="shadow-sm" right lazy>
              <template #button-content>
                <fa icon="user-shield" fixed-width class="mr-2" />
                Admin
              </template>
              <b-dropdown-group>
                <template #header>
                  <fa icon="user" fixed-width class="mr-2 text-dark" />
                  <span class="text-uppercase font-weight-bold"> Users </span>
                </template>
                <b-dropdown-form style="width: 320px; max-width: 100vw" method="GET" action="/users">
                  <b-form-group label="Search users" label-class="sr-only" class="mb-1">
                    <b-form-input autofocus size="sm" name="q_cont" placeholder="Search by name, organization, ..." />
                  </b-form-group>
                </b-dropdown-form>
                <b-dropdown-item href="/users/new">
                  <fa icon="plus" fixed-width class="mr-2 text-secondary" />
                  New user
                </b-dropdown-item>
                <b-dropdown-item href="/users/bulk_import/new">
                  <fa icon="upload" fixed-width class="mr-2 text-secondary" />
                  Bulk import users
                </b-dropdown-item>
                <b-dropdown-item href="/users">
                  <fa icon="list" fixed-width class="mr-2 text-secondary" />
                  All users
                </b-dropdown-item>
              </b-dropdown-group>
              <b-dropdown-divider />
              <b-dropdown-group v-if="isAdmin || isSupport">
                <template #header>
                  <fa icon="users" fixed-width class="mr-2 text-dark" />
                  <span class="text-uppercase font-weight-bold"> Groups </span>
                </template>
                <b-dropdown-form style="width: 320px; max-width: 100vw" method="GET" action="/groups">
                  <b-form-group label="Search groups" label-class="sr-only" class="mb-1">
                    <b-form-input size="sm" name="name_cont" placeholder="Search by name" />
                  </b-form-group>
                </b-dropdown-form>
                <b-dropdown-item v-if="isAdmin" href="/groups/new">
                  <fa icon="plus" fixed-width class="mr-2 text-secondary" />
                  New group
                </b-dropdown-item>
                <b-dropdown-item href="/groups">
                  <fa icon="list" fixed-width class="mr-2 text-secondary" />
                  All groups
                </b-dropdown-item>
              </b-dropdown-group>
              <b-dropdown-divider />
              <b-dropdown-group v-if="isAdmin">
                <template #header>
                  <fa icon="cube" fixed-width class="mr-2 text-dark" />
                  <span class="text-uppercase font-weight-bold"> Applications </span>
                </template>
                <b-dropdown-item href="/oauth/applications/new">
                  <fa icon="plus" fixed-width class="mr-2 text-secondary" />
                  New application
                </b-dropdown-item>
                <b-dropdown-item href="/oauth/applications">
                  <fa icon="list" fixed-width class="mr-2 text-secondary" />
                  All applications
                </b-dropdown-item>
              </b-dropdown-group>
            </b-dropdown>
          </li>
          <li class="nav-item mr-4">
            <b-dropdown variant="link" toggle-class="p-0" menu-class="shadow-sm" right no-caret>
              <template #button-content>
                <b-img :src="userAvatarPath" class="border shadow-sm" height="38" width="38" fluid rounded="circle" />
              </template>
              <b-dropdown-group :header="userUid">
                <b-dropdown-item :href="userProfilePathFor('/profile')">
                  <fa icon="user" fixed-width class="mr-2 text-secondary" />
                  Profile
                </b-dropdown-item>
                <b-dropdown-item :href="userProfilePathFor('/account')">
                  <fa icon="cog" fixed-width class="mr-2 text-secondary" />
                  Account
                </b-dropdown-item>
                <b-dropdown-item :href="userProfilePathFor('/email')">
                  <fa icon="envelope" fixed-width class="mr-2 text-secondary" />
                  Email
                </b-dropdown-item>
              </b-dropdown-group>
              <b-dropdown-divider />
              <b-dropdown-group header="Security">
                <b-dropdown-item :href="userProfilePathFor('/security/password')">
                  <fa icon="shield" fixed-width class="mr-2 text-secondary" />
                  Password
                </b-dropdown-item>
                <b-dropdown-item :href="userProfilePathFor('/security/2fa')">
                  <fa icon="mobile-screen-button" fixed-width class="mr-2 text-secondary" />
                  2FA
                </b-dropdown-item>
                <b-dropdown-item :href="userProfilePathFor('/security/login-history')">
                  <fa icon="id-card" fixed-width class="mr-2 text-secondary" />
                  Login history
                </b-dropdown-item>
              </b-dropdown-group>
              <b-dropdown-divider />
              <b-dropdown-item href="/users/sign_out">
                <fa icon="sign-out" fixed-width class="mr-2 text-secondary" />
                Log Out
              </b-dropdown-item>
            </b-dropdown>
          </li>
        </ul>

        <div v-if="hasUser">
          <button class="navbar-toggler" type="button" aria-label="Toggle navigation" @click="toggleMenu">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    userId: {
      type: String,
      default: undefined
    },
    userUid: {
      type: String,
      default: undefined
    },
    isAdmin: {
      type: Boolean
    },
    isSupport: {
      type: Boolean
    },
    authorizedApplications: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      collapse: true
    }
  },
  computed: {
    homePath() {
      return this.hasUser ? '/dashboard' : '/'
    },
    userProfilePath() {
      return `/users/${this.userId}`
    },
    userAvatarPath() {
      return `/users/${this.userId}/avatar?size=38`
    },
    applicationLogoPath() {
      return (logoId) => `/logos/${logoId}/serve`
    },
    hasUser() {
      return !!this.userId
    },
    productionApplications() {
      if (!Array.isArray(this.authorizedApplications)) {
        return []
      }
      return this.authorizedApplications.filter((app) => app.env_type === 'production' || app.env_type === null)
    },
    hasApplications() {
      return this.productionApplications.length > 0
    }
  },
  methods: {
    toggleMenu() {
      this.collapse = !this.collapse
    },
    userProfilePathFor(href) {
      return `${this.userProfilePath}#${href}`
    }
  }
}
</script>

<style lang="scss">
@use 'sass:math';
@import '../utils/variables';

.navbar {
  position: relative;
  z-index: $zindex-sticky - 10;

  .nav-item {
    a.nav-link {
      padding-left: $nav-link-padding-x;
      padding-right: $nav-link-padding-x;
      text-transform: uppercase;
      color: #333;
      text-decoration: none;
      border-bottom: none;
    }

    & > a.nav-link.ask-for-help {
      padding-left: 1rem;
      display: inline-block;
      position: relative;
      color: #fff;
      z-index: 10;
      font-weight: bold;
      white-space: nowrap;
    }

    & > a.nav-link.ask-for-help:after {
      content: '';
      z-index: -1;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: $info;
    }

    &__applications {
      &__menu {
        &__content.show {
          display: flex;
          flex: column;
          flex-wrap: wrap;
        }

        &__content {
          max-width: 90vw;
          min-width: 350px;
          width: 350px;
          overflow: auto;
          max-height: 80vh;

          ::-webkit-scrollbar {
            width: 6px;
          }

          ::-webkit-scrollbar-track {
            background: $secondary;
          }

          ::-webkit-scrollbar-thumb {
            background: $primary;
          }

          &__item {
            width: calc(math.div(100%, 3) - 1rem);
            margin: 0.5rem;

            &__name {
              margin: $spacer 0;
              margin-bottom: 0;
              white-space: normal;
              display: flex;
              align-items: flex-start;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  &-actions {
    border-right: 1px solid $border-color;
  }

  @include media-breakpoint-down(sm) {
    &-actions {
      border: 0;
      border-bottom: 1px solid $border-color;
    }

    &-collapse {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background: #fff;
      z-index: $zindex-sticky - 10;
      padding: $navbar-padding-y $navbar-padding-x;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      transition: none;
      box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.16);
    }
  }
}
</style>
